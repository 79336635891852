import React, { useState, useEffect } from 'react';
import { Title, ContentInfo, Container } from './styled';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import LogoPolicies from 'src/shared/assets/images/Logo-Policies.svg';
import LogoTerms from 'src/shared/assets/images/Logo-Terms.svg';
import { colorText } from '@utils/colorText';
import { TProps } from './types';
import Viewport from 'src/shared/hooks/viewport';
const ContainerTYP = (props: TProps) => {
    const { title, info, slug } = props;
    const viewport = Viewport();

    return (
        <Container>
            {title.includes('Términos') ? (
                <img src={LogoTerms} alt='Logo términos y condiciones' />
            ) : (
                <img src={LogoPolicies} alt="Logo politicas de privacidad" />
            )}
            {}
            <Title dangerouslySetInnerHTML={{ __html: colorText(title)}} slug={slug === "terminos-condiciones-oki" ? true: false} />
             <hr/>
            <ContentInfo>{documentToReactComponents(JSON.parse(info))}</ContentInfo>
        </Container>
    );
};

export default ContainerTYP;
