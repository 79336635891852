import React from 'react';
import { Breadcrumbs, Layout, Logo, SEO } from '@componentsShared';
import siteUrl from '@utils/siteUrl';
import ContainerTYP from 'src/components/organisms/containerTYP';
import Viewport from 'src/shared/hooks/viewport';
import { WrapperTerms } from './styled';
export type TPage = {
    name: string;
    slug: string;
    metaData: {
        title: string;
        descripcion: string;
        image: {
            file: {
                url: string;
            };
        };
    };
};
const SimplePage = (props: any) => {
    const { pageContext } = props;
    const { data, slug } = pageContext;
    const viewport = Viewport();

    let crumbs = [
        {
            crumbLabel: 'Inicio',
            pathname: '/'+ data.metaData[0].title
        },
        {
            crumbLabel: data.metaData[0].title,
            pathname: siteUrl + '/' + slug
        }
    ];
    
    return (
        <Layout>
            <SEO
                title={data.metaData[0].title}
                description={data.metaData[0].descripcion}
                keyWords={data.metaData[0].keyWords}
                slug={siteUrl + '/' + slug}
            />
            <WrapperTerms>
                {viewport.desktop && <Breadcrumbs crumbs={crumbs} />}
                <ContainerTYP title={data.name} info={data.content.raw} slug={slug} />
            </WrapperTerms>
        </Layout>
    );
};
export default SimplePage;
