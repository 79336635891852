import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';

export type IpropsTYP = {
    slug: boolean;
};

export const Container = styled.div`
    text-align: center;
    img {
        display: none;
        ${breakpoint('md')({
            display: 'block',
            margin: '0 auto 14px auto'
        })}
    }
    hr{
        border-bottom-width: 0px;
        opacity: 0.5;
        border: 1px solid ${(props) => props.theme.colors.disabled.gray};
        margin: 20px 0px 17px;
        ${breakpoint('md')({
            display: 'none'
        })}
    }
    ${breakpoint('md')({
            marginTop:'55px'
        })}

`;
export const ContentInfo = styled.div`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    height: 450px;
    overflow-y: auto;
    word-wrap: break-word;
    text-align: start;
    font-size: 14px;
    padding-right: 12px;

    a {
        color: ${(props) => props.theme.colors.principal.greenBase};
    }
    h3 {
        margin: 15px 0px 15px 0px;
        font-weight: bold;
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }
    p {
        margin: 10px 0px 10px 0px;
        line-height: 20px;
    }
    p:first-child {
        margin: 0px 0px 10px 0px;
    }

    ul {
        margin-top: 18px;
        margin-bottom: 27px;
    }
    ol {
        margin-top: 27px;
        margin-bottom: 27px;
    }
    ul li {
        margin-left: 40px;
        list-style: decimal;
    }
    ol li {
        margin-left: 40px;
        list-style: disc;
    }
    p b {
        font-weight: bold;
    }
    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    ::-webkit-scrollbar:vertical {
        width: 2px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.disabled.gray};
    }
    ::-webkit-scrollbar-track {
        background: ${(props) => props.theme.colors.backgrounds.gray500};
        
    }
    ${breakpoint('md')({
        fontSize:'16px',
        margin: '30px 20px 35px 45px',
        padding:'0px 22px 22px 0px'
    })}
    ${breakpoint('lg')({
       '::-webkit-scrollbar-thumb':{
            background: '${(props) => props.theme.colors.backgrounds.gray500}'
        },
       '::-webkit-scrollbar-track':{
            background: '${(props) => props.theme.colors.disabled.gray}'
        }
    })}
  
`;

export const Title = styled.h1<IpropsTYP>`
    font-family: ${(props) => props.theme.fonts.mon_semiBold};
    font-size: 18px;
    text-align: center;
    max-width: ${(props) => (props.slug ? '277px' : '309px')};
    margin: auto;

    span {
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }

    ${breakpoint('lg')({
        fontSize: '28px'
    })}

    ${breakpoint('md')({
        maxWidth: '823px',
        margin: 'auto'
    })}
`;
